// Mood Board Color
export const BG_PRIMARY = 'rgba(222,218,213,1)';
export const BG_SECONDARY = 'rgba(78,56,56,1)';
export const BG_ALTERNATIVE = '#FFFFFF';
export const BG_OPACITY = '#323030';

// Text Color
export const TEXT_PRIMARY = 'rgba(0,0,0,1)';
export const TEXT_SECONDARY = 'rgba(149,125,110,1)';
export const TEXT_ALTERNATIVE = 'rgba(255,255,255,1)';

// Music & Navigation Color
export const NAVIGATION_COLOR = '#321d1d'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
